<template>
  <div class="auth-content align-center">
    <img src="~assets/images/auth/auth-top-bg.png" class="auth-top-bg" alt="">
    <img src="~assets/images/auth/auth-bot-bg.png" class="auth-bot-bg" alt="">
    <div class="audit-content">
      <div class="audit">

        <div class="edit-icon flex-nowrap">已有账号，
          <div @click="quitLogin">
            <span class="blue">快捷登录</span>
          </div>
        </div>
        <div class="seat"></div>
        <el-divider></el-divider>
        <div class="content">
          <div class="title">
            君莲书院注册协议
          </div>
          <div class="top">
            <p>如果您选择接受本条款，即表示您同意接受协议各项条件的约束。如果您不同意本服务条款，则不能获得使用本协议的权利。您若有违反本条款规定，君莲书院有权随时中止或终止您对君莲书院产品的使用资格并保留追究相关法律责任的权利。本协议条款一旦发生变更，君莲书院将公布修改内容。修改后的服务条款一旦公布即有效代替原来的服务条款。您可随时登录君莲书院官方APP查阅最新版服务条款。</p>
            <p class="top-b">欢迎您来到君莲书院。</p>
            <p>请您仔细阅读以下条款，如果您对本协议的任何条款表示异议，您可以选择不进入君莲书院。当您注册成功，无论是进入君莲书院，还是在君莲书院上发布任何内容（即「内容」），均意味着您（即「用户」）完全接受本协议项下的全部条款。</p>
          </div>
          <div class="center">
            <p><span class="bold">一、	产品保护条款 </span></p>
            <p>1、君莲书院产品是归属解螺旋(上海)医学科技有限公司（下统称为 “我们”）版权所有。君莲书院产品的一切版权以及与君莲书院产品相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图表、色彩、版面设计、数据、印刷材料、或电子文档等均受著作权法和国际著作权条约以及其他知识产权法律法规的保护。 </p>
            <p>2、您须明白，使用本协议产品涉及到互联网服务，可能会受到各个环节不稳定因素的影响。因此服务存在不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险。您须承担以上风险，我们不作担保。</p>
            <p>3、如我们的系统发生故障影响到本协议的正常运行，我们承诺在第一时间内与相关单位配合，及时处理进行修复。但您因此而产生的经济损失，我们不承担责任。此外，我们保留不经事先通知为维修保养、升级或其他目的暂停本协议任何部分的权利。</p>
            <p>4、使用本协议必须遵守国家有关法律和政策等，维护国家利益，保护国家安全，并遵守本条款，对于您违法或违反本条款的使用(包括但不限于言论发表、传送等)而引起的一切责任，由您负全部责任，概与我们无关，导致我们损失的，我们有权要求赔偿，并有权立即停止向其提供服务，保留相关记录，并保留配合司法机关追究法律责任的权利。</p>
            <p>5、您同意个人隐私信息是指那些能够对您进行个人辨识或涉及个人通信的信息，包括下列信息：您的姓名，身份证号，手机号码，IP地址，电子邮件地址信息。而非个人隐私信息是指您登录的账号、对软件的操作状态以及使用习惯等您的操作记录信息和其他一切个人隐私信息范围外的普通信息。我们将会采取合理的措施保护您的个人隐私信息，除法律或有法律赋予权限的政府部门要求或您同意等原因外，君莲书院未经您同意不向第三方公开、 透露您个人隐私信息。您同意，为了运营和改善君莲书院的技术和服务，我们可以在无须再另行通知或提示您的情况下，自己收集使用或向第三方提供使用您的非个人隐私信息，以有助于君莲书院向用户提供更好的用户体验和提高服务质量。</p>
            <p><span class="bold">二、用户使用须知 </span></p>
            <p>特别提醒您，使用互联网必须遵守国家有关的政策和法律，如刑法、国家安全法、保密法、计算机信息系统安全保护条例等，保护国家利益，保护国家安全，对于违法使用互联网络而引起的一切责任，由您负全部责任。 </p>
            <p>1、您不得使用君莲书院产品发送或传播任何妨碍社会治安或非法、虚假、骚扰性、侮辱性、恐吓性、伤害性、破坏性、挑衅性、淫秽色情性等内容的信息。 </p>
            <p>2、您不得使用君莲书院产品发送或传播敏感信息和违反国家法律制度的信息。</p>
            <p>3、您保证以真实的身份注册使用君莲书院的产品，向君莲书院所提供的个人身份资料信息真实、完整、有效，依据法律规定和约定对所提供的信息承担相应的法律责任。如果资料发生变化，您应及时更改。君莲书院会及时、有效地提供该项服务。在安全完成本协议的登记程序后，您应维持密码及账号的机密安全。您应对任何人利用您的密码及账号所进行的活动负完全的责任，解螺旋(上海)医学科技有限公司无法对非法或未经您授权使用您账号及密码的行为做出甄别，因此君莲书院公司不承担任何责任。</p>
            <p>4、盗取他人号码或利用网络通讯骚扰他人，均属于非法行为。您不得采用测试、欺骗等任何非法手段，盗取其他用户的账号和对他人进行骚扰。 </p>
            <p>5、我们在此郑重提请您注意，任何经由本协议以上传、下载、张贴、电子邮件或任何其他方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息、用户的登记资料或其他资料等（以下简称“内容”），无论公开还是私下传送，均由内容提供者承担责任。同时，为了提高、改进君莲书院各种服务的用户体验，您同意君莲书院对凡是您经由本服务通过上传、张贴等任何方式发布到君莲书院产品的任何文字、图片及其他信息资料等进行无偿的修改、复制、传播等使用。君莲书院无法监控经由本协议传送之内容，也无法对用户的使用行为进行全面控制，因此不保证内容的合法性、正确性、完整性、真实性或品质等；您已预知使用本服务时，可能会接触到令人不快、不适当或令人厌恶之内容，并同意将自行加以判断并承担所有风险，而不依赖于君莲书院。但在任何情况下，我们公司有权依法停止任何前述内容的服务并采取相应行动，包括但不限于暂停用户使用本协议的全部或部分，保存有关记录，并向有关机关报告。但君莲书院有权(但无义务)依其自行之考量，拒绝和删除可经由本协议提供之违反本条款的或其他引起君莲书院或其他用户反感的任何内容。</p>
            <p>6、君莲书院产品属于群体类产品，使用君莲书院软件产品服务的用户之间引发的任何纠纷我们公司将不负责任。</p>
            <p><span class="bold">三、关于用户学习服务 </span></p>
            <p>在君莲书院产品中，目前君莲书院提供了大量的视频学习课程。您同意、理解并遵循以下条款，也同时适用，对双方均有约束力： </p>
            <p>1、您除了可以按照本协议的约定在君莲书院使用学习之外，不得进行任何侵犯君莲书院的知识产权的行为，或者进行其他的有损于君莲书院或其他用户合法权益的行为。君莲书院也绝对不会允许您从事这些行为，亦有权采取技术措施防止您从事这些行为，包括但不限于：</p>
            <p>（1）修改君莲书院课程中的版权信息，或者擅自修改君莲书院课程的名称； </p>
            <p>（2）进行任何君莲书院学习公平性或者其他影响其他用户正常秩序的行为，如主动或被动刷积分、合伙作弊、使用外挂或者其他的作弊软件、利用BUG（又叫“漏洞”或者“缺陷”）来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、BUG公之于众；</p>
            <p>（3）利用劫持域名服务器等技术非法侵入、破坏用户学习的服务器软件系统，或者修改、增加、删除、窃取、截留、替换服务器软件系统中的数据，或者非法挤占君莲书院的服务器空间，或者实施其他的使之超负荷运行的行为； </p>
            <p>（4）进行任何诸如发布广告、推广的商业行为，或者进行任何非法的侵害君莲书院利益的行为等；</p>
            <p>（5）冒充君莲书院管理员或是官方工作人员发布任何诈骗或虚假信息；  </p>
            <p>（6）发表、转发、传播含有谩骂、诅咒、诋毁、攻击、诽谤、侵害君莲书院和/或第三方权益内容的，或者含有封建迷信、淫秽、色情、下流、恐怖、暴力、凶杀、赌博、反动、扇动民族仇恨、危害祖国统一、颠覆国家政权等让人反感、厌恶的内容的非法言论，或者设置含有上述内容的用户昵称；</p>
            <p>（7）在日常学习环节中进行恶意刷分、恶意评论，恶意回答回复等恶意破坏君莲书院公共秩序的行为；</p>
            <p>（8）利用用户发布评论，评价，问答，回答，回复等故意传播恶意程序或计算机病毒，或者利用笔记发表、转发、传播侵犯第三方知识产权、肖像权、姓名权、名誉权、隐私或其他合法权益的文字、图片、照片、程序、视频、图像和/或动画等资料。 </p>
            <p>2、您充分理解到：用户学习观看使时的相关用户数据将会占据君莲书院服务器空间。长期保留您在使用和享受君莲书院提供的学习服务的过程中所产生的全部数据，将会大量地挤占服务器空间，影响整个君莲书院服务器的运行速度，增加君莲书院的运营成本，是完全没有必要的。因此，君莲书院将会定期将服务器上存储的一些过往的学习数据转移或者永久地删除。</p>
            <p>3、君莲书院一向遵守国家有关保护青少年身心健康的法律、政策，会按照国家的相关法规保护青少年的身心健康，您会遵守国家相关的法规及君莲书院根据相关法规制定的关于保护青少年身心健康各种规则。</p>
            <p>4、您如有任何违反协议或相关法规的，君莲书院有权采取下列措施当中的一种或几种：</p>
            <p>（1）立即断开您当前使用的计算机与君莲书院服务器之间的网络连接；</p>
            <p>（2）暂时禁止您凭借当前使用的君莲书院账户登录君莲书院学习使用；</p>
            <p>（3）降低或者清除您当前使用的君莲书院账户在学习当中的积分、等级和经验；</p>
            <p>（4）永久性地、不可撤销地将您发布的广告、虚假信息或者非法言论删除，或者采取其他的阻止其传播的措施； </p>
            <p>（5）永久性地、不可撤销地将您非法获取的积分、等级或经验或清零；</p>
            <p>（6）永久性地、不可撤销地删除您当前使用的君莲书院账户项下的所有积分、等级、经验等资料以及相应的学习数据； </p>
            <p>（7）采取上列措施之外的其他的措施。</p>
            <p>5、您充分理解到：用户可能从事的违反约定或相关法规的行为，是一种即时性的瞬间即可让众多用户知晓的行为。君莲书院如果不立即采取各种处理措施，可能会造成非常严重非常恶劣的后果。对此，您是给予充分理解的，并完全同意君莲书院采取相关措施对相关违反约定或相关法规行为进行处理。</p>
            <p>6、您如果对君莲书院就您使用的君莲书院账户采取的相关措施有异议，则应当在知道君莲书院采取该等措施之日7个工作日内向君莲书院客服邮箱service@helixlife.com.cn服务反馈，提供相应的情况，说明您的异议理由。君莲书院将会根据您提供的情况及说明的理由自行判断是否应当终止执行该等措施。但这不会导致该等措施无效，也不影响异议期间该等措施的执行。君莲书院客户服务如果在采取该等措施3个月未接到任何反馈的，则视为您没有任何异议，君莲书院不再接受任何有关的反馈。</p>
            <p>7、君莲书院保留随时地、不事先通知地、不需要任何理由地、单方面地中止、终止本协议或提供相关学习服务的权利。该等中止、终止，可能是因为国家法律、法规、政策及国家机关的命令或者其他的诸如地震、火灾、海啸、台风、罢工、战争等不可抗力事件，还可能是上列原因之外的其他原因。若君莲书院的该等中止、终止行为给你造成任何损失的，您同意不向君莲书院主张任何赔偿或其他责任。</p>
            <p>8、金币问题</p>
            <p>（1）金币是专供君莲书院用户在君莲书院平台使用的虚拟币，需先充值后，设置好规则，用户通过设置规则对用户进行奖励发放。金币可用于君莲书院平台上兑换产品，除此之外，不得用于其他用途。</p>
            <p>（2）如果用户使用他人代充或以其他违规方式充值造成其他用户或第三方权益受损时，不得因此要求君莲书院作任何补偿或赔偿，君莲书院保留随时冻结其帐户余额、暂停或终止其使用各项充值服务及禁用其帐户的权利。如君莲书院有理由相信用户的君莲书院金币帐户或使用情况涉及违规充值、作弊或异常状况，君莲书院有权拒绝该用户继续使用金币，并按本协议采取冻结、禁用帐户等相关措施。</p>
            <p>（3）金币一经充值成功，除法律法规明确规定外，在任何情况下不能兑换为法定货币，不能转让他人。用户间交易金币构成对本协议的违反，君莲书院有权不通知用户而采取适当措施，以确保君莲书院不为违规用户提供金币交易的平台服务。金币不支持提现功能。</p>
            <p>（4）本平台只显示本小组内的金币，并非用户金币，若想查看自己账户余额金币，需到APP端自行查看。</p>
            <p>9、账户使用与保管 </p>
            <p>（1）君莲书院有权审查您注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障您账户的安全、有效；您有义务妥善保管其账户及密码，并正确、安全地使用您的账户及密码。任何一方未尽上述义务导致账户密码遗失、账户被盗等情形而给您和他人的民事权利造成损害的，应当承担由此产生的法律责任。</p>
            <p>（2）您对登录后所持账户产生的行为依法享有权利和承担责任。 </p>
            <p>（3）您发现您的账户或密码被他人非法使用或有使用异常的情况的，应及时根据君莲书院公布的处理方式通知君莲书院，并有权通知君莲书院采取措施暂停该账户的登录和使用。</p>
            <p>（4）君莲书院根据您的通知采取措施暂停用户账户的登录和使用的，君莲书院应当要求您提供并核实与其注册身份信息相一致的个人有效身份信息。</p>
            <p>（5）登录方式分为账号登录或游客登录，游客模式下仍可进行充值或消费，但只可在当前设备使用。如若您更换手机、电脑等终端设备或该等终端设备损坏的，您在该游客模式下所有相关数据可能都将会被清空，且无法查询和恢复。如因此造成您任何损失的，均由您自行承担。所以为了保护您的课程数据安全，建议您采用账号登录方式进行登录。</p>
            <p>（6）君莲书院核实您所提供的个人有效身份信息与所注册的身份信息相一致的，应当及时采取措施暂停用户账户的登录和使用。 </p>
            <p>（7）君莲书院违反（6）款项的约定，未及时采取措施暂停用户账户的登录和使用，因此而给您造成损失的，应当承担其相应的法律责任。</p>
            <p>（8）您没有提供真实的个人有效身份证件或者您提供的个人有效身份证件与所注册的身份信息不一致的，君莲书院有权拒绝您依据本条第（3）项提出的请求。</p>
            <p>（9）您为了维护您的合法权益，向君莲书院提供与所注册的身份信息相一致的个人有效身份信息时，君莲书院应当为您提供账户注册人证明、原始注册信息等必要的协助和支持，并根据需要向有关行政机关和司法机关提供相关证据信息资料。 </p>
            <p>10、服务的中止与终止 </p>
            <p>（1）您有发布违法信息、严重违背社会公德、以及其他违反法律禁止性规定的行为，君莲书院有权立即终止对您提供服务。 </p>
            <p>（2）您在接受君莲书院服务时实施违反约定或相关法规的不正当行为的，君莲书院有权终止对您提供服务。</p>
            <p>（3）您没有以真实的个人身份信息进行注册，或实施违反本协议或相关法规的行为，君莲书院有权中止对您提供全部或部分服务；君莲书院采取中止措施会以合适的方式通知您并告知中止期间，中止期间是合理的，中止期间届满君莲书院应当及时恢复对您的服务。</p>
            <p>（4）君莲书院根据本条约定中止或终止对您提供部分或全部服务的，君莲书院应负举证责任。</p>
            <p>11、用户信息保护 </p>
            <p>（1）君莲书院要求您提供与其个人身份有关的信息资料时，会事先以明确而易见的方式向您公开其隐私权保护政策和个人信息利用政策，并采取必要措施保护您的个人信息资料的安全。 </p>
            <p>（2）未经您许可君莲书院不得向任何第三方提供、公开或共享您注册资料中的姓名、个人有效身份证件号码、联系方式、家庭住址等个人身份信息，但下列情况除外： </p>
            <p>A、您或您的监护人授权君莲书院披露的；</p>
            <p>B、有关法律要求君莲书院披露的； </p>
            <p>C、司法机关或行政机关基于法定程序要求君莲书院提供的；</p>
            <p>D、君莲书院为了维护自己合法权益而向您提起诉讼或者仲裁时； </p>
            <p>E、应您的监护人的合法要求而提供您个人身份信息时。</p>
            <p><span class="bold">四、用户付费协议</span></p>
            <p>为保障用户权益，请用户在付费之前，详细阅读此服务协议（以下简称“本协议”）所有内容。君莲书院已经发布的或将来可能发布的各类规则,均为本协议不可分割的组成部分，与协议正文具有同等法律效力。</p>
            <p>1、用户规则 </p>
            <p>用户对以其帐号发生的或通过其帐号发生的一切活动和事件（包括但不限于用户发表的任何内容以及由此产生的任何结果）负全部法律责任。  </p>
            <p>（1）用户在君莲书院有偿获得课程内容或服务时，用户需先登录或注册君莲书院帐号，或通过页面提示选用其他可用帐号进行登录。用户在使用君莲书院服务时登录的帐号是确认您身份的唯一依据。 </p>
            <p>（2）用户理解并同意：君莲书院提供有偿内容或服务实行先付款后使用的方式，用户及时、足额、合法的支付所需的款项，是您使用君莲书院有偿内容或服务的前提。 </p>
            <p>（3）用户理解并同意：君莲书院发布的课程目前都是免费学习的内容，即用户只需学习一次，就可以学习该课程所有已发布或即将发布的课时。已学的课程，用户可以重复学习。如需添加更多增值服务将适当性收取费用。 </p>
            <p>（4）用户知悉并同意：用户无权对已购买的课程进行修改、出租、租赁、出借、出售、分发、复制、创作衍生品。若用户违反本条规定，君莲书院有权视情况采取如下措施： </p>
            <p>A、取消用户继续使用该课程的权利； </p>
            <p>B、限制/冻结用户的帐号；</p>
            <p>C、要求用户退还其通过出售、转让、许可等其他方式取得的收益；</p>
            <p>D、其他可以采取的补救措施。 </p>
            <p>（5）用户了解并同意：君莲书院可能不定期的对收费课程进行更新（包括但不限于：更新课程内容、改进课程安排）而无需经过用户的事先同意。</p>
            <p>（6）用户应保管好自己的帐号和密码（包括但不限于：君莲书院帐号密码、支付平台账号密码），如因用户未保管好自己的帐号和密码而对自己、对君莲书院造成损害的，用户将负全部责任。另外，用户应对用户帐号中的所有活动和事件负全责。用户同意若发现有非法使用用户的帐号或出现安全漏洞的情况，立即通告君莲书院，君莲书院将会尽力予以妥善解决。</p>
            <p>3、本协议的修订 </p>
            <p>君莲书院有权对本协议进行调整或补充，除非另有明确规定，君莲书院所推出的新功能和新服务，均无条件的适用本协议。 </p>
            <p>4、其他约定</p>
            <p>（1）所有权及知识产权：君莲书院上服务中包含的任何文字、图表、音频、视频（包括但不限于图表、动画、音频、视频、界面、数据和程序、代码、文档）等信息或材料均受著作权法、商标法和/或其它法律法规的保护。非经君莲书院书面同意用户不得擅自使用、修改、复制、传播、改变、散布、发行或发表上述内容。如有违反，用户同意承担由此给君莲书院造成的一切损失。  </p>
            <p>（2）用户在君莲书院学习中所产生的评论、答疑、讨论，代码，个人作品（包括但不限于代码，图片，数据等），君莲书院均有权在君莲书院及相关宣传媒体/品进行展示，并无须支付任何费用。 </p>
            <p>（3）本协议适用中华人民共和国的法律。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关内容将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。</p>
            <p>（4）本协议自发布之日起实施，并构成用户和君莲书院之间的共识。 </p>
            <p>（5）君莲书院不行使、未能及时行使或者未充分行使本协议或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响君莲书院在将来行使该权利。</p>
            <p>（6）如果您对本协议内容有任何意见或疑问，请给我们的客服邮箱发邮件：[service@helixlife.com.cn]。 </p>
            <p><span class="bold">五、服务声明</span></p>
            <p>君莲书院公司特别提示请您注意，君莲书院公司为了保障公司业务发展和调整的自主权，君莲书院公司拥有随时修改或中断服务而不需通知您的权利，君莲书院公司行使修改或中断服务的权利不需对您或任何第三方负责。您必须在同意本条款的前提下，君莲书院公司才开始对您提供服务。 </p>
            <p><span class="bold">六、适用法律</span></p>
            <p>本服务条款的解释，效力及纠纷的解决，适用于中华人民共和国大陆法律。</p>
            <p>若您和君莲书院之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，您在此完全同意将纠纷或争议提交君莲书院所在地上海市徐汇区人民法院管辖。君莲书院公司拥有对以上各项条款内容的解释权及修改权。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-hint-box align-center">
      {{$static.footer_text}}
    </div>
  </div>
</template>

<script>
export default {
  name: "Perfect",
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    quitLogin() {
      this.$router.push('/login')
    }
  },
}
</script>

<style lang="less" scoped>
@import "~assets/less/auth/auth.less";
@import "~assets/less/auth/audit.less";
</style>

